import { useState, useEffect, useRef } from "preact/hooks";
import ClipboardButton from "./ClipboardButton.tsx"; 

interface CodeBlockProps {
  code: string;
  
}

export default function CodeBlock({ code,  }: CodeBlockProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [fontSize, setFontSize] = useState<string>("12px"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = globalThis.innerWidth;

      // Apply responsive font size based on screen width
      if (screenWidth < 640) {
        setFontSize("10px");
      } else if (screenWidth < 768) {
        setFontSize("12px");
      } else if (screenWidth < 1024) {
        setFontSize("14px");
      } else {
        setFontSize("16px");
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    globalThis.addEventListener("resize", handleResize);

    return () => {
      // Cleanup event listener on component unmount
      globalThis.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div class="relative pt-0">
      {/* Clipboard Button to copy code to clipboard */}
      <ClipboardButton text={code} />

      {/* Conditionally render the code block if noCode is not true */}
        <pre
          class="bg-black text-white overflow-auto rounded-lg scrollbar-always px-3 pb-1 pt-3"
          style={{ maxHeight: "30rem", fontSize }} // Approximately 50 lines in 1000px height
        >
          <code ref={codeRef} >
            {code}
          </code>
        </pre>

    </div>
  );
}
